<template>
  <div class="pa-3">
    <div class="my-8 text-center" data-test="header">
      <div class="primary--text">
        {{ getNoteTimeFrame("DEBIT", payoutStore.getPayoutTypeSelectedSource) }}
      </div>

      <read-more-tooltip bold>
        {{ formVerbiage.tooltipContent }}
      </read-more-tooltip>
    </div>

    <v-form ref="form" v-model="isFormValid" :disabled="disableForm">
      <v-row v-if="isEditing">
        <v-col cols="12">
          <app-text-field
            v-model="form.nameOnCard"
            :label="$t('DebitCardForm.nameOnCard')"
            disabled
            data-test="name-on-card"
          />
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12" md="6">
          <name-text-field
            v-model.trim="form.firstName"
            :label="$t('DebitCardForm.firstName')"
            :disabled="isEditing"
            data-test="first-name"
            aria-required="true"
            name="firstname"
            autocomplete="given-name"
          />
        </v-col>

        <v-col cols="12" md="6">
          <name-text-field
            v-model.trim="form.lastName"
            :label="$t('DebitCardForm.lastName')"
            :disabled="isEditing"
            data-test="last-name"
            aria-required="true"
            autocomplete="family-name"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <secure-text-field
            id="card-number"
            v-model.trim="form.cardNumber"
            v-maska:[options]
            :label="$t('DebitCardForm.cardNumber')"
            :rules="
              isEditing
                ? []
                : [required(), detectCardType, orNumericIgnoringSpaces(16, 19)]
            "
            :disabled="isEditing"
            data-test="card-number"
            aria-required="true"
            autocomplete="cc-number"
          />
        </v-col>

        <v-col cols="12" md="6">
          <secure-text-field
            id="re-enter-card-number"
            v-model.trim="form.confirmCardNumber"
            v-maska:[options]
            :label="$t('DebitCardForm.reEnter')"
            :rules="isEditing ? [] : [required(), cardNumbersMatch]"
            :disabled="isEditing"
            data-test="re-enter-number"
            aria-required="true"
            autocomplete="cc-number"
            @paste.prevent
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="getDisableCardExpiryConfig" cols="12" md="6">
          <app-text-field
            v-model="form.expDate"
            v-maska:[options1]
            :label="$t('DebitCardForm.expDate')"
            :rules="[required(), isExpired]"
            aria-required="true"
            data-test="exp-date"
            autocomplete="cc-exp"
          />
        </v-col>

        <v-col v-if="nicknameEnabled" cols="12" md="6">
          <nickname-text-field v-model="form.nickname" />
        </v-col>
        <!--Added CVC Field enableMasterCardField-->

        <v-col v-if="getCVVfieldConfig" cols="12" md="4">
          <app-text-field
            id="cvc-number"
            v-model.trim="form.cvcNumber"
            type="password"
            :label="$t('DebitCardForm.cvcNumber')"
            :disabled="isEditing"
            :maxlength="4"
            data-test="cvc-number"
            :rules="
              isEditing
                ? []
                : [
                    required(),
                    minLength(3, $t('DebitCardForm.invalidCVCFormat')),
                    numberOnly($t('DebitCardForm.invalidCVCFormat')),
                  ]
            "
            aria-required="true"
            autocomplete="cc-csc"
          />
        </v-col>
        <div v-if="getCVVfieldConfig" class="d-flex align-center my-6">
          <v-tooltip
            location="top"
            max-width="300"
            content-class="tooltip-content-custom"
            data-test="transfer-to-tooltip"
            :open-on-click="true"
          >
            <template #activator="{ on, attrs }">
              <svg-icon
                type="mdi"
                :path="mdiInformation"
                class="cursor-pointer"
                color="primary"
                size="20"
                data-test="cvv-tooltip"
                v-bind="attrs"
                v-on="on"
              >
              </svg-icon>
            </template>
            <span>{{ $t("AcceptPayout.cvvTooltip") }}</span>
          </v-tooltip>
        </div>
      </v-row>
      <!--- New two Fields -->

      <v-row v-show="enableMasterCardField"> </v-row>

      <terms-and-conditions-checkbox
        v-model="form.terms"
        data-test="accept-terms"
        class="payoutTermsCondition"
      />

      <div v-if="transient" class="mt-6">
        <v-checkbox
          v-if="!isGuest"
          v-model="savePayoutAccount"
          class="customized_info"
          :error-messages="errorSavedPayoutMethod"
          :label="$t('PayoutsAvailable.savePayoutAccount')"
          data-test="save-payout-method"
          aria-required="true"
          :disabled="savedPayoutMethods.length > 4"
        >
        </v-checkbox>
      </div>

      <v-row v-if="savePayoutAccount" class="mb-6">
        <v-col cols="12" md="6">
          <nickname-text-field v-model="form.nickname" />
        </v-col>
      </v-row>

      <v-checkbox
        v-if="!transient"
        v-model="form.default"
        color="primary"
        data-test="default-payment"
        :label="$t('DebitCardForm.defaultPayoutMethod')"
        :disabled="isEditing && selectedPayoutMethod.card.default"
      />

      <div class="d-flex justify-center">
        <v-btn
          class="mr-4 mr-md-6"
          tile
          :disabled="disburseLoading || disableForm"
          elevation="0"
          color="primary"
          data-test="add-account"
          :loading="persistLoading || disburseLoading"
          :class="{
            custom_min_width_100: isEditing || transient,
            custom_min_width_150: !(isEditing || transient),
          }"
          @click="submit"
          >{{ submitButtonText }}</v-btn
        >
        <v-btn
          class="ml-4 ml-md-6 custom_min_width_100"
          tile
          variant="outlined"
          color="primary"
          data-test="cancel"
          @click="showConfirmCancel = true"
          >{{ $t("DebitCardForm.cancel") }}</v-btn
        >
      </div>

      <div v-if="disableForm" class="d-flex px-3 pt-6 px-sm-4">
        <v-alert type="error" tile>
          <span data-test="disburse-error-message">
            {{ disburseError }}
          </span>
        </v-alert>
      </div>
    </v-form>

    <payout-methods-confirm-cancel-dialog
      v-model="showConfirmCancel"
      :editing="isEditing"
    />
  </div>
</template>

<script>
import { mdiArrowTopRight, mdiInformation } from "@mdi/js";

import { isPast, isValid, parse, lastDayOfMonth } from "date-fns";
import { mapState } from "pinia";
import {
  orNumericIgnoringSpaces,
  required,
  minLength,
  numberOnly,
  masterCardType,
  visaCardType,
  detect_CardType,
} from "@/utils/rules";
import { setVerbiage } from "@/utils/payout";
import PayoutMethodsConfirmCancelDialog from "@/components/payout-methods/PayoutMethodsConfirmCancelDialog";
import AppTextField from "@/components/forms/AppTextField";
import NameTextField from "@/components/forms/NameTextField";
import SecureTextField from "@/components/forms/SecureTextField";
import isEqual from "lodash/isEqual";
import TermsAndConditionsCheckbox from "@/components/forms/TermsAndConditionsCheckbox";
import { useAuthStore } from "@/stores/auth";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { usePayoutStore } from "@/stores/payout";
import { useTitle } from "@/composables/title";
import { useTransientPayoutMethod } from "@/composables/transient-payout-method";
import { usePersistPayoutMethod } from "@/composables/persist-payout-method";
import { computed } from "vue";
import { useDisburse } from "@/composables/disburse";
import ReadMoreTooltip from "@/components/payout-methods/ReadMoreTooltip";
import NicknameTextField from "@/components/forms/NicknameTextField";
import { getNoteTimeFrame } from "@/utils/payout";
import { i18n } from "@/plugins/i18n";

const now = new Date();

export default {
  name: "DebitCardForm",

  components: {
    NicknameTextField,
    ReadMoreTooltip,
    SecureTextField,
    NameTextField,
    AppTextField,
    PayoutMethodsConfirmCancelDialog,
    TermsAndConditionsCheckbox,
  },

  setup() {
    const authStore = useAuthStore();
    const payoutStore = usePayoutStore();
    const formVerbiage = setVerbiage(payoutStore.getPayoutTypeSelectedSource);
    const title = authStore.isGuest
      ? formVerbiage.guestTitle
      : formVerbiage.registerTitle;
    useTitle(title);
    const options1 = { mask: "##/##" };

    const { store } = useTransientPayoutMethod();
    const {
      disburse,
      disburseLoading,
      error: disburseError,
      disabled: transferDisabled,
    } = useDisburse();

    const { persistLoading, persist, isEditing } =
      usePersistPayoutMethod("DebitCardForm");

    const options = isEditing.value
      ? { mask: "" }
      : { mask: "#### #### #### #### ###" };
    const onSubmit = computed(() => (payoutStore.selected ? store : persist));
    // const daysFrame = getNoteTimeFrame(
    //   "DEBIT",
    //   payoutStore.getPayoutTypeSelectedSource
    // );

    // const daysFrame = computed(() => {
    //   return;
    //   getNoteTimeFrame("DEBIT", payoutStore.getPayoutTypeSelectedSource);
    // });
    // // const timeFrame = i18n.global.t(daysFrame);

    return {
      disburse,
      disburseLoading,
      disburseError,
      transferDisabled,
      authStore,
      payoutStore,
      isEditing,
      persistLoading,
      onSubmit,
      required,
      orNumericIgnoringSpaces,
      minLength,
      numberOnly,
      mdiInformation,
      masterCardType,
      visaCardType,
      formVerbiage,
      detect_CardType,
      getNoteTimeFrame,
      options,
      options1,
      // timeFrame,
      //daysFrame,
    };
  },

  data() {
    return {
      mdiArrowTopRight,
      isFormValid: false,
      form: {
        firstName: "",
        lastName: "",
        nameOnCard: " ",
        cardNumber: "",
        confirmCardNumber: "",
        expDate: "",
        nickname: null,
        cvcNumber: "",
        terms: false,
        default: false,
      },
      savePayoutAccount: false,
      showConfirmCancel: false,
    };
  },

  computed: {
    ...mapState(useAuthStore, ["session", "isGuest"]),
    ...mapState(usePayoutMethodStore, { selectedPayoutMethod: "selected" }),
    ...mapState(usePayoutStore, { selectedPayout: "selected" }),
    ...mapState(usePayoutStore, ["payoutTypeSelectedSource"]),
    ...mapState(usePayoutMethodStore, { savedPayoutMethods: "persisted" }),

    disbursementFlowActive() {
      return !!this.selectedPayout;
    },

    errorSavedPayoutMethod() {
      const messageArray = [];
      this.savedPayoutMethods.length > 4
        ? messageArray.push(
            i18n.global.t("PayoutsAvailable.savePayoutAccountError")
          )
        : [];
      return messageArray;
    },

    cardNumbersMatch() {
      return (
        isEqual(this.form.cardNumber, this.form.confirmCardNumber) ||
        i18n.global.t("DebitCardForm.confirmCardNumbers")
      );
    },

    apiSchema() {
      const [month, year] = this.form.expDate.split("/");
      const nameOnCard =
        this.form.nameOnCard === " "
          ? `${this.form.firstName} ${this.form.lastName}`
          : this.form.nameOnCard;
      const cardBrand = this.payoutTypeSelectedSource.toLowerCase();
      return {
        recipientProfileInfo: {
          recipientId: this.session.recipientId,
          firstName: this.firstName,
          lastName: this.lastName,
        },
        source: "DEBIT",
        label: this.form.nickname ? this.form.nickname : "",
        card: {
          cardNumber: this.form.cardNumber.replaceAll(" ", ""),
          nameOnCard,
          securityCode: this.form.cvcNumber,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          expiryDate: { month, year },
          default: this.form.default,
          cardBrand,
        },
      };
    },

    submitButtonText() {
      return this.isEditing || this.transient
        ? i18n.global.t("DebitCardForm.submit")
        : i18n.global.t("DebitCardForm.addAccount");
    },

    transient() {
      return this.isGuest || this.disbursementFlowActive;
    },

    disableForm() {
      return this.transferDisabled;
    },
    getCVVfieldConfig() {
      return this.payoutStore.securityCodeRequired;
    },

    getDisableCardExpiryConfig() {
      return !this.payoutStore.disableCardExpiry;
    },
    nicknameEnabled() {
      return (
        process.env.VUE_APP_PAYOUT_METHOD_NICKNAMES_ENABLED === "true" &&
        !this.transient
      );
    },
    enableMasterCardField() {
      return (
        process.env.VUE_APP_PAYOUT_METHOD_ENABLE_MasterCardFields === "true"
      );
    },

    detectCardType() {
      switch (this.payoutTypeSelectedSource) {
        case "VISA":
          return this.visaCardType();
        case "MASTERCARD":
          return this.masterCardType();
        default:
          return this.detect_CardType();
      }
    },
  },

  created() {
    if (this.isEditing) {
      this.prePopulateForm();
    }
  },

  methods: {
    prePopulateForm() {
      this.form.nameOnCard = this.selectedPayoutMethod.card.nameOnCard;
      if (this.payoutTypeSelectedSource === "VISA") {
        this.form.cardNumber = "********";
        this.form.confirmCardNumber = "********";
      } else {
        this.form.cardNumber = "********";
        this.form.confirmCardNumber = "********";
      }
      this.form.expDate = `${this.selectedPayoutMethod.card.expiryDate.month}/${this.selectedPayoutMethod.card.expiryDate.year}`;
      this.form.default = this.selectedPayoutMethod.card.default;
      this.form.nickname = this.selectedPayoutMethod.label;
      this.form.cvcNumber = "****";
    },

    isExpired(v) {
      //get the date of user input value and calculate the last day of month for debit card exp
      const getStartDateOfMonthWithValue = parse(v, "MM/yy", now);

      const expiryDate = lastDayOfMonth(getStartDateOfMonthWithValue);
      const expired = !isValid(expiryDate) || isPast(expiryDate);

      return !expired || i18n.global.t("DebitCardForm.expiredCard");
    },

    async submit() {
      const valid = await this.$refs.form.validate();
      if (!valid.valid) return;

      await this.onSubmit(this.apiSchema);
      if (this.transient) {
        this.payoutStore.setSavePayoutAccount(
          this.savePayoutAccount ? "true" : "false"
        );
        this.disburse();
      }
    },
  },
};
</script>
<style scoped>
.logos {
  padding-left: 10px;
  margin-top: 10px;
}
.h-7 {
  height: 1.75rem;
  width: 100%;
}
</style>
